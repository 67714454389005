import styles1 from "../styles/tables";
import styles2 from "../styles/modales";

import React, { useState } from 'react';
import Navbar from "../components/navbar";
import Radium from "radium";
import {useQuery} from "react-query";
import {getMe} from "../api/users";
import {imports} from "../api/imports";
import {useNavigate} from "react-router-dom";

const styles = {...styles1, ...styles2};


function Imports() {
    const navigate = useNavigate();
    const {data: me} = useQuery('me', () => getMe(navigate));
    const [loading, setLoading] = useState(false);

    const doImport = async () => {
        setLoading(true);
        const response = await imports();
        if(response) {
            setLoading(false);
        } else {
            alert('Erreur lors de l\'import');
        }
    }

    return (
        <div style={styles.page}>
            <a href={"/"} style={styles.header}>
                <img src={"/assets/images/logo.png"} style={styles.logo} alt="logo" />
            </a>

            <Navbar page={'profil'} me={me} />

            <div style={styles.containerFluid}>
                <div className={'row mb-4'}>

                    <div className={'col-12'}>
                        <div className={'tableBorder'}>
                            <div style={styles.h1}>
                                Imports :
                            </div>

                            <div style={styles.contentBtn}>
                                <div className={'row-table mt-40'}>
                                    {!loading ?
                                        <a style={styles.btnKReverse} href={'#'} onClick={() => doImport()}>Importer</a>
                                        :
                                        <p>En cours...</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Radium(Imports);
