
import Modal from 'react-bootstrap/Modal';
import styles1 from "../styles/tables";
import styles2 from "../styles/modales";

import React, { useState } from 'react';
import Navbar from "../components/navbar";
import Radium from "radium";
import {useQuery, useQueryClient} from "react-query";
import {
    createUser,
    deleteUser as deleteUserApi,
    getMe,
    getProprietaires,
    updateUser
} from "../api/users";
import {useNavigate} from "react-router-dom";

const styles = {...styles1, ...styles2};


function Proprietaires() {
    const navigate = useNavigate();
    const {data: me} = useQuery('me', () => getMe(navigate));
    const queryClient = useQueryClient();
    const {data: proprietaires} = useQuery('proprietaires', getProprietaires);
    const [selectedProprietaire, setSelectedProprietaire] = useState(null);
    const [addProprietaire, setAddProprietaire] = useState(false);
    const [editProprietaire, setEditProprietaire] = useState(false);
    const [deleteProprietaire, setDeleteProprietaire] = useState(false);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');

    const doAddProprietaire = async () => {
        let user = {firstname, lastname, email, role: 'proprietaire'};
        if (password !== '' && password_confirmation !== '') {
            user.password = password ;
            user.password_confirmation = password_confirmation ;
        }
        let response = await createUser(user);
        if (response?.data?.id) {
            console.log(response);
            setFirstname(null);
            setLastname(null);
            setEmail(null);
            setAddProprietaire(false);
            queryClient.invalidateQueries(['proprietaires'])
        } else {
            alert('Veuillez vérifier les champs ! ');
        }
    }

    const doEditProprietaire = async () => {
        let user = {firstname, lastname, email}
        if (password !== '' && password_confirmation !== '') {
            user.password = password ;
            user.password_confirmation = password_confirmation ;
        }
        const response = await updateUser(selectedProprietaire.id, user);
        if(response?.data?.id) {
            setFirstname(null);
            setLastname(null);
            setEmail(null);
            setSelectedProprietaire(null);
            setEditProprietaire(false);
            queryClient.invalidateQueries(['proprietaires'])
        } else {
            alert('Veuillez vérifier les champs ! ');
        }
    }

    const doDeleteProprietaire = async () => {
        await deleteUserApi(selectedProprietaire.id);
        setSelectedProprietaire(null);
        setDeleteProprietaire(false);
        queryClient.invalidateQueries(['proprietaires'])
    }

    const Form = () => (
        <div className={"row"} style={styles.mt20}>
            <div className={"col-6"}>
                <label htmlFor="label" style={styles.label}>Nom</label>
                <input type="text" id="label" placeholder={"Nom utilisateur"} style={styles.input} value={lastname} onChange={(e) => setLastname(e.target.value)} />
            </div>
            <div className={"col-6"} >
                <label htmlFor="label" style={styles.label}>Prénom</label>
                <input type="text" id="label" placeholder={"Prénom utilisateur"} style={styles.input} value={firstname} onChange={(e) => setFirstname(e.target.value)} />
            </div>
            <div className={"col-12"} style={styles.mt20}>
                <label htmlFor="label" style={styles.label}>Adresse mail</label>
                <input type="text" id="label" placeholder={"Adresse mail"} style={styles.input} value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className={"col-12"} style={styles.mt20}>
                <label htmlFor="label" style={styles.label}>Mot de passe</label>
                <input type="text" id="label" placeholder={"Mot de passe"} style={styles.input} value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div className={"col-12"} style={styles.mt20}>
                <label htmlFor="label" style={styles.label}>Confirmation du mot de passe</label>
                <input type="text" id="label" placeholder={"Confirmation du mot de passe"} style={styles.input} value={password_confirmation} onChange={(e) => setPassword_confirmation(e.target.value)} />
            </div>
        </div>
    )

    return (
        <div style={styles.page}>
            <a href={"/"} style={styles.header}>
                <img src={"/assets/images/logo.png"} style={styles.logo} alt="logo" />
            </a>
            <Navbar page={'proprietaires'} me={me}  />
            <div style={styles.SectionInfo}>
                <div style={styles.h1}>
                    Liste des <span style={styles.h1W}>propriétaires</span>
                </div>
                <a href={'#'} style={styles.btnRow} onClick={() => setAddProprietaire(true)}>
                    <img src={"/assets/icons/add-user.png"} style={styles.iconAdd} alt="add-user" />
                    Ajouter un propriétaire
                </a>
            </div>

            <div style={styles.containerFluid}>
                <div className={'row mb-4'}>

                    <div className={'col-12'}>
                        <div className={'tableBorder'}>

                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th style={styles.headerTable} scope="col"></th>
                                    <th style={styles.headerTable} scope="col">Nom</th>
                                    <th style={styles.headerTable} scope="col">Prénom</th>
                                    <th style={styles.headerTable} scope="col">Location sous gestion</th>
                                    <th style={styles.headerTable} scope="col">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {proprietaires?.map(proprietaire => (<tr style={styles.trTable} key={proprietaire.id}>
                                        <td><img src={"/assets/icons/user.png"} style={styles.icon} alt="user" /></td>
                                        <td style={styles.textWeight}>{proprietaire?.firstname}</td>
                                        <td style={styles.textWeight}>{proprietaire?.lastname}</td>
                                        <td>
                                            <div style={styles.rowTable}>
                                                <a style={{...styles.btnK, ...styles.mr10}} href={'/users/'+proprietaire.id+'/logements'}>{proprietaire?.properties_length} locations</a>
                                            </div>
                                        </td>
                                        <td>
                                            <div style={styles.rowTable}>
                                                <a style={{...styles.btnKReverse, ...styles.mr10}} href={'#'} onClick={() => { setFirstname(proprietaire?.firstname); setLastname(proprietaire?.lastname); setEmail(proprietaire?.email) ; setSelectedProprietaire(proprietaire); setEditProprietaire(true)}}>Modifier</a>
                                                <a style={styles.btnKRed} href={'#'} onClick={() => { setSelectedProprietaire(proprietaire); setDeleteProprietaire(true)}}>Supprimer</a>
                                            </div>
                                        </td>
                                    </tr>))}

                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

            <Modal
                show={addProprietaire}
                onHide={() => setAddProprietaire(false)}
                contentClassName="modal-little-custom"
                fullscreen={false}
            >
                <Modal.Body>
                    <div style={{...styles.borderHr, ...styles.frameTitlePopup}}>
                        <div style={{...styles.icon, ...styles.mr10}} />
                        <div style={styles.titleH1Popup}>
                            Ajouter un propriétaire
                        </div>
                        <a href={'/proprietaires'}>
                            <img src={"/assets/icons/close.png"} alt="icon-close" style={{...styles.icon, ...styles.mr10}} />
                        </a>
                    </div>
                    <div style={styles.contentModal}>
                        {Form()}
                        <div style={styles.contentBtn}>
                            <div className={'row-table mt-40'}>
                                <a style={styles.btnKReverse} href={'#'} onClick={() => doAddProprietaire()}><img src={"/assets/icons/add-home.png"} style={{...styles.icon, ...styles.mr10}} alt="add-home" /> Ajouter un propriétaire</a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={editProprietaire}
                onHide={() => setEditProprietaire(false)}
                contentClassName="modal-little-custom"
                fullscreen={false}
            >
                <Modal.Body>
                    <div style={{...styles.borderHr, ...styles.frameTitlePopup}}>
                        <div style={{...styles.icon, ...styles.mr10}} />
                        <div style={styles.titleH1Popup}>
                            Modifier un propriétaire
                        </div>
                        <a href={'/proprietaires'}>
                            <img src={"/assets/icons/close.png"} alt="icon-close" style={{...styles.icon, ...styles.mr10}} />
                        </a>
                    </div>
                    <div style={styles.contentModal}>
                        {Form()}
                        <div style={styles.contentBtn}>
                            <div className={'row-table mt-40'}>
                                <a style={styles.btnKReverse} href={'#'} onClick={() => doEditProprietaire()}><img src={"/assets/icons/add-home.png"} style={{...styles.icon, ...styles.mr10}} alt="add-home" /> Enregistrer</a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={deleteProprietaire}
                onHide={() => setDeleteProprietaire(false)}
                contentClassName="modal-little-custom"
                fullscreen={false}
            >
                <Modal.Body>
                    <div style={{...styles.borderHr, ...styles.frameTitlePopup}}>
                        <div style={{...styles.icon, ...styles.mr10}} />
                        <div style={styles.titleH1Popup}>
                            Supprimer le propriétaire
                        </div>
                        <a href={'/proprietaires'}>
                            <img src={"/assets/icons/close.png"} alt="icon-close" style={{...styles.icon, ...styles.mr10}} />
                        </a>
                    </div>
                    <div style={styles.contentModal}>
                        <div style={styles.contentLabel}>
                            <label htmlFor="label" style={styles.label}>Supprimer le propriétaire</label>
                        </div>
                        <div style={styles.contentBtn}>
                            <div className={'row-table mt-40'}>
                                <a style={styles.btnKReverse} href={'#'} onClick={() => doDeleteProprietaire()}> Supprimer</a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


        </div>
    );
}

export default Radium(Proprietaires);
