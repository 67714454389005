import Radium from "radium";
import {logout} from "../api/session";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPowerOff} from '@fortawesome/free-solid-svg-icons'


const styles = {
    p200:{
        padding: "0px 200px",
        '@media (max-width: 900px)': {
            padding: '10px',
        },
    },
    bgBlue:{
        backgroundColor: "#4A9FC2",
    },
}
function NavBar({page = '', me}) {
    const navigate = useNavigate();

    const doLogout = async () => {
        await logout();
        navigate(`/login`);
    }
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark" style={styles.bgBlue}>
                <div className="container-fluid" style={styles.p200}>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className={`nav-link ${page === 'dashboard' && 'active'}`} aria-current="page" href="/">Dashboard</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${page === 'in-outs' && 'active'}`} aria-current="page" href="/in-outs">Entrées/Sorties</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${page === 'logements' && 'active'}`} aria-current="page" href="/logements">Logements</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${page === 'reservations' && 'active'}`} href="/reservations">Réservations</a>
                            </li>
                            {/*<li className="nav-item">
                                <a className={`nav-link ${page === 'locataires' && 'active'}`} href="/">Locataires</a>
                            </li>*/}
                            {me?.role === 'admin' && (<li className="nav-item">
                                <a className={`nav-link ${page === 'concierges' && 'active'}`} href="/concierges">Concierges</a>
                            </li>)}
                            {me?.role === 'admin' && (<li className="nav-item">
                                <a className={`nav-link ${page === 'proprietaires' && 'active'}`} href="/proprietaires">Propriétaires</a>
                            </li>)}
                            {me?.role === 'admin' && (<li className="nav-item">
                                <a className={`nav-link ${page === 'users' && 'active'}`} href="/users">Admins</a>
                            </li>)}
                            {me?.role === 'admin' && (<li className="nav-item">
                                <a className={`nav-link ${page === 'imports' && 'active'}`} href="/imports">Imports</a>
                            </li>)}
                            <li className="nav-item">
                                <a className={`nav-link ${page === 'profil' && 'active'}`} href="/profil">Profil</a>
                            </li>


                        </ul>
                    </div>
                    <div className="d-flex" >
                        <div className="nav-item">
                            <a className={`nav-item nav-link active`} href="https://www.kyrnovarent.com/" target={"_blank"} rel="noreferrer">Espace locations</a>
                        </div>
                        <a className={`nav-link`} href="#" onClick={() => doLogout()}>  <FontAwesomeIcon icon={faPowerOff} color={"white"} /></a>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Radium(NavBar);
