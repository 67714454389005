import axiosInstance from "../interceptor/tokenInterceptor";
import {rootApi} from "./config";
import qs from "qs";

export const getProperties = async (user_id = null) => {
    let response ;
    if (user_id) {
        response = await axiosInstance.get(rootApi + '/properties/user/'+user_id);
    } else {
        response = await axiosInstance.get(rootApi + '/properties');
    }
    return response.data;
};

export const getProperty = async (property_id = null) => {
    const response = await axiosInstance.get(rootApi + '/properties/'+property_id);
    return response.data;
};

export const deleteUserProperty = async (user_id, property_id) => {
    const response = await axiosInstance.delete(rootApi + '/users/'+user_id+'/properties/'+property_id);
    return response.data;
};

export const addUserProperty = async (user_id, property_id) => {
    const response = await axiosInstance.get(rootApi + '/users/'+user_id+'/properties/'+property_id);
    return response.data;
};


export const updateProperty = async (id, property) => {
    const response = await axiosInstance
        .patch(rootApi + '/properties/' + id, qs.stringify(property))
        .catch(err => {
            console.log(JSON.stringify(err.response.data));
            return {response: false, data: err.response.data};
        });

    if (response.data) {
        // eneregistrer
        return {response: true, data: response.data};
    } else {
        return {response: false};
    }
};
