import styles1 from "../styles/tables";
import styles2 from "../styles/modales";
import Modal from 'react-bootstrap/Modal';
import React, {useState} from 'react';
import Navbar from "../components/navbar";
import Radium from "radium";
import {useQuery, useQueryClient} from "react-query";
import {createUser, deleteUser as deleteUserApi, getMe, getUsers, updateUser} from "../api/users";
import {useNavigate} from "react-router-dom";
const styles = {...styles1, ...styles2};

function Users() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {data: users} = useQuery('users', getUsers);
    const {data: me} = useQuery('me', () => getMe(navigate));
    const [selectedUser, setSelectedUser] = useState(null);
    const [addUser, setAddUser] = useState(false);
    const [editUser, setEditUser] = useState(false);
    const [deleteUser, setDeleteUser] = useState(false);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');

    const doAddUser = async () => {
        const response = await createUser({firstname, lastname, email});
        if(response?.data?.id) {
            setFirstname(null);
            setLastname(null);
            setEmail(null);
            setAddUser(false);
            queryClient.invalidateQueries(['users'])
        } else {
            alert('Veuillez vérifier les champs ! ');
        }
    }

    const doEditUser = async () => {
        const response = await updateUser(selectedUser.id, {firstname, lastname, email});
        if(response?.data?.id) {
            setFirstname(null);
            setLastname(null);
            setEmail(null);
            setSelectedUser(null);
            setEditUser(false);
            queryClient.invalidateQueries(['users'])
        } else {
            alert('Veuillez vérifier les champs ! ');
        }
    }

    const doDeleteUser = () => {
        deleteUserApi(selectedUser.id);
        setSelectedUser(null);
        setDeleteUser(false);
        queryClient.invalidateQueries(['users'])
    }

    const Form = () => (
        <div className={"row"} style={styles.mt20}>
            <div className={"col-6"}>
                <label htmlFor="label" style={styles.label}>Nom</label>
                <input type="text" id="label" placeholder={"Nom utilisateur"} style={styles.input} value={lastname} onChange={(e) => setLastname(e.target.value)} />
            </div>
            <div className={"col-6"} >
                <label htmlFor="label" style={styles.label}>Prénom</label>
                <input type="text" id="label" placeholder={"Prénom utilisateur"} style={styles.input} value={firstname} onChange={(e) => setFirstname(e.target.value)} />
            </div>
            <div className={"col-12"} style={styles.mt20}>
                <label htmlFor="label" style={styles.label}>Adresse mail</label>
                <input type="text" id="label" placeholder={"Adresse mail"} style={styles.input} value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
        </div>
    )

    return (
        <div style={styles.page}>
            <div style={styles.header}>
                <img src={"/assets/images/logo.png"} style={styles.logo} alt="logo" />
            </div>
            <Navbar page={'users'} me={me} />
            <div style={styles.SectionInfo}>
                <div style={styles.h1}>
                    Liste des <span style={styles.h1W}>administrateurs</span>
                </div>
                <a href={'#'} style={styles.btnRow} onClick={() => setAddUser(true)}>
                    <img src={"/assets/icons/add-user.png"} style={{...styles.icon, ...styles.mr10}} alt="add-user" />
                    Ajouter un administrateur
                </a>
            </div>

            <div style={styles.containerFluid}>
                <div className={'row mb-4'}>
                    <div className={'col-12'}>
                        <div className={'tableBorder'}>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th style={styles.headerTable} scope="col"></th>
                                    <th style={styles.headerTable} scope="col">Nom</th>
                                    <th style={styles.headerTable} scope="col">Prénom</th>
                                    <th style={styles.headerTable} scope="col">Adresse mail</th>
                                    <th style={styles.headerTable} scope="col">Rôle</th>
                                    <th style={styles.headerTable} scope="col">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {users?.map(user => (<tr style={styles.trTable} key={user.id}>
                                    <td><img src={"/assets/icons/user.png"} style={styles.icon} alt="user" /></td>
                                    <td style={styles.textWeight}>{user?.firstname}</td>
                                    <td style={styles.textWeight}>{user?.lastname}</td>
                                    <td style={styles.textWeight}>{user?.email}</td>
                                    <td style={styles.textWeight}>{user?.role}</td>
                                    <td>
                                        <div style={styles.rowTable}>
                                            <a style={{...styles.btnKReverse, ...styles.mr10}} href={'#'} onClick={() => { setFirstname(user?.firstname); setLastname(user?.lastname); setEmail(user?.email) ; setSelectedUser(user); setEditUser(true)}}>Modifier</a>
                                            <a style={styles.btnKRed} href={'#'} onClick={() => { setSelectedUser(user); setDeleteUser(true)}}>Supprimer</a>
                                        </div>
                                    </td>
                                </tr>))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>



            <Modal
                show={addUser}
                onHide={() => setAddUser(false)}
                contentClassName="modal-little-custom"
                fullscreen={false}
            >
                <Modal.Body>
                    <div style={{...styles.borderHr, ...styles.frameTitlePopup}}>
                        <div style={{...styles.icon, ...styles.mr10}} />
                        <div style={styles.titleH1Popup}>
                            Ajouter un administrateur
                        </div>
                        <a href={'/users'}>
                            <img src={"/assets/icons/close.png"} alt="icon-close" style={{...styles.icon, ...styles.mr10}} />
                        </a>
                    </div>
                    <div style={styles.contentModal}>
                        {Form()}
                        <div style={styles.contentBtn}>
                            <div className={'row-table mt-40'}>
                                <a style={styles.btnKReverse} href={'#'} onClick={() => doAddUser()}><img src={"/assets/icons/add-home.png"} style={{...styles.icon, ...styles.mr10}} alt="add-home" /> Ajouter l'administrateur</a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={editUser}
                onHide={() => setEditUser(false)}
                contentClassName="modal-little-custom"
                fullscreen={false}
            >
                <Modal.Body>
                    <div style={{...styles.borderHr, ...styles.frameTitlePopup}}>
                        <div style={{...styles.icon, ...styles.mr10}} />
                        <div style={styles.titleH1Popup}>
                            Modifier un administrateur
                        </div>
                        <a href={'/users'}>
                            <img src={"/assets/icons/close.png"} alt="icon-close" style={{...styles.icon, ...styles.mr10}} />
                        </a>
                    </div>
                    <div style={styles.contentModal}>
                        {Form()}
                        <div style={styles.contentBtn}>
                            <div className={'row-table mt-40'}>
                                <a style={styles.btnKReverse} href={'#'} onClick={() => doEditUser()}><img src={"/assets/icons/add-home.png"} style={{...styles.icon, ...styles.mr10}} alt="add-home" /> Modifier l'administrateur</a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={deleteUser}
                onHide={() => setDeleteUser(false)}
                contentClassName="modal-little-custom"
                fullscreen={false}
            >
                <Modal.Body>
                    <div style={{...styles.borderHr, ...styles.frameTitlePopup}}>
                        <div style={{...styles.icon, ...styles.mr10}} />
                        <div style={styles.titleH1Popup}>
                            Supprimer l'administrateur
                        </div>
                        <a href={'/users'}>
                            <img src={"/assets/icons/close.png"} alt="icon-close" style={{...styles.icon, ...styles.mr10}} />
                        </a>
                    </div>
                    <div style={styles.contentModal}>
                        <div style={styles.contentLabel}>
                            <label htmlFor="label" style={styles.label}>Supprimer l'administrateur</label>
                        </div>
                        <div style={styles.contentBtn}>
                            <div className={'row-table mt-40'}>
                                <a style={styles.btnKReverse} href={'#'} onClick={() => doDeleteUser()}> Supprimer</a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    );
}

export default Radium(Users);
