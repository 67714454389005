import styles1 from "../styles/tables";
import styles2 from "../styles/modales";

import React, { useState } from 'react';
import Navbar from "../components/navbar";
import Radium from "radium";
import {useQuery, useQueryClient} from "react-query";
import {getMe, updateUser} from "../api/users";
import {useNavigate} from "react-router-dom";

const styles = {...styles1, ...styles2};


function Profil() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {data: me} = useQuery('me', () => getMe(navigate), {
        onSuccess: (data) => {
            setFirstname(data?.firstname);
            setLastname(data?.lastname);
        }
    });
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');

    const doEditProfil = async () => {
        let response = await updateUser(me.id, {firstname, lastname});
        if (response?.data?.id) {
            console.log(response);
            setFirstname(null);
            setLastname(null);
            queryClient.invalidateQueries(['me'])
        } else {
            alert('Veuillez vérifier les champs ! ');
        }
    }

    const doEditPassword = async () => {
        let response = await updateUser(me.id, {old_password: oldPassword, password: newPassword, password_confirmation: newPassword2});
        if (response?.data?.id) {
            console.log(response);
            setOldPassword(null);
            setNewPassword(null);
            setNewPassword2(null);
            queryClient.invalidateQueries(['me'])
        } else {
            alert('Veuillez vérifier les champs ! ');
        }
    }

    const FormPassword = () => (
        <div className={"row"} style={styles.mt20}>
            <div className={"col-12"}>
                <label htmlFor="label" style={styles.label}>Ancien mot de passe</label>
                <input type="password" id="label" placeholder={"Ancien mot de passe"} style={styles.input} value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
            </div>
            <div className={"col-6"} >
                <label htmlFor="label" style={styles.label}>Nouveau mot de passe</label>
                <input type="password" id="label" placeholder={"Nouveau mot de passe"} style={styles.input} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
            </div>
            <div className={"col-6"}>
                <label htmlFor="label" style={styles.label}>Répéter le mot de passe</label>
                <input type="password" id="label" placeholder={"Répéter le mot de passe"} style={styles.input} value={newPassword2} onChange={(e) => setNewPassword2(e.target.value)} />
            </div>
        </div>
    )

    const FormProfil = () => (
        <div className={"row"} style={styles.mt20}>
            <div className={"col-6"}>
                <label htmlFor="label" style={styles.label}>Nom</label>
                <input type="text" id="label" placeholder={"Nom"} style={styles.input} value={lastname} onChange={(e) => setLastname(e.target.value)} />
            </div>
            <div className={"col-6"} >
                <label htmlFor="label" style={styles.label}>Prénom</label>
                <input type="text" id="label" placeholder={"Prénom utilisateur"} style={styles.input} value={firstname} onChange={(e) => setFirstname(e.target.value)} />
            </div>
        </div>
    )

    return (
        <div style={styles.page}>
            <a href={"/"} style={styles.header}>
                <img src={"/assets/images/logo.png"} style={styles.logo} alt="logo" />
            </a>
            <Navbar page={'profil'} me={me} />
            <div style={styles.SectionInfo}>
                <div style={styles.h1}>
                    Profil utilisateur : <span style={styles.h1W}>{me?.firstname} {me?.lastname}</span>
                </div>
            </div>

            <div style={styles.containerFluid}>
                <div className={'row mb-4'}>

                    <div className={'col-12'}>
                        <div className={'tableBorder'}>
                            <div style={styles.h1}>
                                Modifier votre profil :
                            </div>

                            {FormProfil()}
                            <div style={styles.contentBtn}>
                                <div className={'row-table mt-40'}>
                                    <a style={styles.btnKReverse} href={'#'} onClick={() => doEditProfil()}>Modifier</a>
                                </div>
                            </div>

                            <div style={styles.h1}>
                                Modifier votre mot de passe :
                            </div>

                            {FormPassword()}
                            <div style={styles.contentBtn}>
                                <div className={'row-table mt-40'}>
                                    <a style={styles.btnKReverse} href={'#'} onClick={() => doEditPassword()}>Modifier</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Radium(Profil);
